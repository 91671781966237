import * as React from "react"
import tw, { styled } from "twin.macro"

const Container = styled.div`
  ${tw`relative py-16 bg-white overflow-hidden`}
`

const InnerContainer = styled.div`
  ${tw`relative px-4 sm:px-6 lg:px-8`}
`

const ContentContainer = ({ children }) => {
  return (
    <Container>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  )
}

export default ContentContainer

import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import ContentContainer from "../components/ContentContainer"
import TextContainer from "../components/TextContainer"

const Team = () => {
  return (
    <Layout>
      <Seo
        title="Nasi wykładowcy"
        description={`Wykładowcy prowadzący kursy`}
      />
      <ContentContainer>
        <TextContainer>
          <h1>Nasi wykładowcy</h1>

          <h2>Bożena Krasnodębska</h2>
          <p>
            Wykształcenie wyższe, ukończone studia na wydziale prawa,
            administacji i ekonomii na Uniwersytecie Wrocławskim, podyplomowe
            studia z zakresu rachunkowości na Akademii Ekonomicznej we
            Wrocławiu, uprawnienia Ministerstwa Finansów do usługowego
            prowadzenia ksiąg. Wielolenia praktyka w kompleksowym prowadzeniu
            rachunkowości w firmach o różnym profilu działalności, nadzór nad
            pracą działów księgowości oraz wdrażaniu programów
            finansowo-księgowych. Autorski program szkoleniowy z zakresu podstaw
            rachunkowości oraz ćwiczeń praktycznych przy wykorzystaniu FK
            Symfonia pozwalający uczestnikom kusu na przyswojenie dużego obszaru
            wiedzy oraz przygotowanie praktyczne do zawodu księgowego.
          </p>

        </TextContainer>
      </ContentContainer>
    </Layout>
  )
}

export default Team

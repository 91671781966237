import React from "react"
import tw, { styled, GlobalStyles } from "twin.macro"

import { initLiveChat } from '../livechat'
import Navigation from "../components/Navigation"
import HeroBanner from "../components/HeroBanner"
import Footer from "../components/Footer"
import CookiesBanner from '../components/CookiesBanner'

const HeroContainer = styled.div`
  ${tw`bg-gray-50 shadow pt-12 md:pt-7`};
`

const NewWebsiteBanner = styled.div`
  ${tw`p-1 bg-blue-700 text-white text-center text-sm fixed w-full z-50 top-0`};
`

const ExternalLink = styled.a`
  ${tw`underline`}
`

const Layout = ({ includeBanner, children }) => {
  React.useEffect(() => {
    initLiveChat()
  }, [])
  return (
    <div>
      <GlobalStyles />
      <HeroContainer>
        <NewWebsiteBanner>
          Szukasz usług rachunkowych? Sprawdź nasze biuro księgowości: <ExternalLink href="https://posterus-ksiegowosc.pl/">posterus-ksiegowosc.pl</ExternalLink>
        </NewWebsiteBanner>
        <Navigation />
        {includeBanner && <HeroBanner />}
      </HeroContainer>
      {children}
      <Footer />
      <CookiesBanner />
    </div>
  )
}

export default Layout

import tw, { styled } from "twin.macro"

const TextContainer = styled.div`
  ${tw`prose prose-lg text-gray-500 mx-auto`}

  li {
    ${tw`my-1`}
  }
`

export default TextContainer
